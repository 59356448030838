module.exports = {
  title: `Minsu's Dev Log`,
  description: `Minsu's blog for storing technical logs`,
  author: `Minsu Kim`,
  introduction: `Software Engineer at KakaoPay Corp.`,
  siteUrl: `https://alstn2468.github.io`, // Your blog site url
  social: {
    twitter: `minsu-kim-336289160`, // Your Twitter account
    github: `alstn2468`, // Your GitHub account
    instagram: ``, // Your Medium account
    facebook: ``, // Your Facebook account
  },
  icon: `content/assets/felog.png`, // Add your favicon
  thumbnail: `https://alstn2468.github.io/thumbnail.png`,
  resume: {
    title: 'About Minsu Kim',
    description: 'docs: Update RESUME.md for introducing myself',
    thumbnail: 'https://alstn2468.github.io/about_thumbnail.png',
  },
  keywords: [
    `blog`,
    `tect blog`,
    `devleoper`,
    `python`,
    `react`,
    `react native`,
    `django`,
    `functional programming`,
  ],
  comment: {
    disqusShortName: '', // Your disqus-short-name. check disqus.com.
    utterances: 'alstn2468/alstn2468.github.io', // Your repository for archive comment
  },
  configs: {
    countOfInitialPost: 10, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: 'eMLdEwU',
  },
  share: {
    facebookAppId: '', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: 'UA-130514342-1', // Add your google analytics tranking ID
  gaTrackingIds: [
    'G-05YJTVGBG5',
  ], // Add your google analytics tranking ID
}
